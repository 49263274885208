import React, { useState, useContext, useEffect } from "react"
import { FirebaseContext } from "../components/Firebase"
import { Link, navigate } from "gatsby";

import { Form } from "../components/common/Form"
import { Input } from "../components/common/Input"
import { Button } from "../components/common/Button"
import { ErrorMessage } from "../components/common/ErrorMessage"

import Main from "../components/Layout/Main";
import FlexContainer from "../components/Layout/FlexContainer";
import FlexItem from "../components/Layout/FlexItem";
import Card from "../components/Layout/Card";

import SEO from "../components/seo"

const ProfilePage = ({ pageContext }) => {
    const [formValues, setFormValues] = useState(() => { return {username: "", bookingNo: "", password: ""}})
    const { user, firebase } = useContext(FirebaseContext)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorMessagePasswordReset, setErrorMessagePasswordReset] = useState('')


    useEffect(() => {
        if(user != null) {
            setFormValues({username: user.username, bookingNo: user.bookingNo})
        }        
    }, [user])

    const handleSubmit = (e) => {
        e.preventDefault();

        //validate input???
        if(formValues.username !== undefined || formValues.bookingNo !== undefined) {
            let username = ""
            let bookingNo = ""
            if(formValues.username !== undefined) {
                username = formValues.username
            }
            if(formValues.bookingNo !== undefined) {
                bookingNo = formValues.bookingNo
            }

            firebase.updateProfile({uid: user.uid, username: username, bookingNo: bookingNo, events: null})
            .then(setErrorMessage("Profile updated"))
            .catch(error => {
                setErrorMessage(error.message)
            })
        }
    }

    const handleInputChange = (e) => {
        e.persist()
        setErrorMessage('')
        setFormValues(currentValues => ({
            ...currentValues,
            [e.target.name]: e.target.value
        }))
    }

    const handleLogoutClick = () => {
        firebase.logout().then(() => navigate("/"))
    }  

    const handleDeleteUserClick = (e) => {
        e.preventDefault();
        firebase.deleteAccount({password: formValues.password})
            .then(() => handleLogoutClick())
            .catch(error => {
                setErrorMessage(error.message)
            })
    }  

    const handlePasswordReset = (e) => {
        firebase.passwordReset({email: null})
        .then(setErrorMessagePasswordReset("Passwort reset email is on the way"))
        .catch(error => {
            setErrorMessagePasswordReset(error.message)
        })
    }  

    pageContext.data = {}
    pageContext.data.desc_event = "Your profile"
    pageContext.data.name_event = "Your profile to zrce.app by zrce.eu"

    return( 
        <section>
            <SEO context={pageContext} noindex={true} />
            <Main>
                <FlexContainer>
                    <FlexItem>
                        {!!user ?
                            <React.Fragment>
                                <Card>  
                                    <h2>Your Profile</h2>
                                    <p>add your booking ID or update your profile</p>
                                </Card>
                            
                                <Card>
                                    <Form onSubmit={handleSubmit}>
                                        <React.Fragment>
                                            <p>Username:</p>
                                            <Input value={formValues.username} name="username" onChange={handleInputChange} placeholder="Username" type="text" minLength={3} />
                                            <p>Booking number:</p>
                                            <Input value={formValues.bookingNo} name="bookingNo" onChange={handleInputChange} placeholder="Booking number" type="text" minLength={5} />
                                        </React.Fragment>
                                        <React.Fragment>
                                            <p>Your email:</p>
                                            <Input placeholder={user.email} name="email" type="text" disabled />
                                        </React.Fragment>
                                        {!!errorMessage &&
                                            <ErrorMessage>
                                                {errorMessage}
                                            </ErrorMessage>
                                        }
                                        <Button type="submit">
                                            Update profile
                                        </Button>
                                    </Form>
                                </Card>

                                <Card>
                                    <h2>Logout</h2>
                                    <p>from Zrce web app</p>
                                </Card>
                                <Card>
                                    {!!user && !!user.email &&
                                    <Button onClick={handleLogoutClick}>
                                        Logout
                                    </Button>
                                    }
                                </Card>

                                <Card>
                                    <h2>Password</h2>
                                    <p>in case you want to reset it...</p>
                                </Card>
                                <Card>
                                    {!!errorMessagePasswordReset &&
                                        <ErrorMessage>
                                            {errorMessagePasswordReset}
                                        </ErrorMessage>
                                    }
                                    {!!user && !!user.email &&
                                    <Button onClick={handlePasswordReset}>
                                        Reset password
                                    </Button>
                                    }
                                </Card>

                                <Card>
                                    <h2>Delete account</h2>
                                    <p>Please provide your password to delete the account. Click password reset if you forgot your password first</p>
                                </Card>
                                <Card>
                                    <Form onSubmit={handleDeleteUserClick}>
                                        <Input value={formValues.password} name="password" onChange={handleInputChange} placeholder="password" type="password" required />
                                        {!!user && !!user.email &&
                                        <Button type="submit">
                                            Delete account
                                        </Button>
                                        }
                                    </Form>
                                </Card>
                            </React.Fragment>
                           : 
                            <React.Fragment>
                            <Card>
                                <h2>Login or register first</h2>
                            </Card>
                            <Card>
                                <Link to="/login"><Button>Login here</Button></Link>
                            </Card>
                            <Card>
                                <Link to="/register"><Button>Register here</Button></Link>
                            </Card>
                            </React.Fragment>
                        }                      
                    </FlexItem>
                </FlexContainer>
            </Main>
        </section>
    )
}

export default ProfilePage
